<template>
    <v-app id="app">
        <router-view />
    </v-app>
</template>

<script>
import Api from "@/services/api.js";

export default {
    watch: {
        $route(to, from) {
            this.aguardarRota();
        }
    },
    mounted() {
        this.$root.$api = new Api();

        this.aguardarRota();
    },
    methods: {
        aguardarRota() {
            if (!this.$route.name) {
                const that = this;

                setTimeout(() => {
                    that.aguardarRota();
                }, 200);
            } else {
                this.verificaLogin();
            }
        },
        verificaLogin() {
            let strRota =
                "https://www.econeteditora.com.br/acesso_lgpd.php?url=" +
                this.$route.fullPath; //.replace(/\//g, "=&=");
        
            if (
                this.$route.name.indexOf("validar") == -1 &&
                this.$route.name != "Home"
                // this.$route.name.indexOf("admin") >= 0
            ) {
                if (localStorage.getItem("token")) {
                    this.isSetToken = true;
                    this.$root.$api
                        .getUrlCompleta(
                            "https://www.econeteditora.com.br/acesso_spt_login.php?id=" +
                                localStorage.getItem("HASH_USER")
                        )
                        .then(response => {
                            if (!response.blLogado) {
                                localStorage.removeItem("token");
                                localStorage.removeItem("nome_usuario");
                                localStorage.removeItem("HASH_USER");

                                window.location.href = strRota;
                            }
                        });
                } else {
                    window.location.href = strRota;
                }
            }
        }
    }
};
</script>
