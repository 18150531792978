export const Utils = {
    handleErrors(fieldName, vueObj, blFem = false) {
        const errors = [];

        if (!vueObj.$v.obForm[fieldName].$dirty) return errors;
        if ("email" in vueObj.$v.obForm[fieldName]) {
            !vueObj.$v.obForm[fieldName].email &&
                errors.push("E-mail inválido");
        }
        if ("required" in vueObj.$v.obForm[fieldName]) {
            !vueObj.$v.obForm[fieldName].required &&
                errors.push("Campo obrigatório");
        }
        if (
            "minLength" in vueObj.$v.obForm[fieldName] &&
            vueObj.$v.obForm[fieldName].minLength == false
        ) {
            let intMin = vueObj.$v.obForm[fieldName].$params.minLength.min;

            errors.push(
                fieldName.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    return a.toUpperCase();
                }) +
                    (blFem ? " inválida" : " inválido") +
                    ", mínimo de " +
                    intMin +
                    " caractéres"
            );
        }
        if (
            "cnpj" in vueObj.$v.obForm[fieldName] &&
            vueObj.$v.obForm[fieldName].cnpj == false
        ) {
            errors.push("CNPJ inválido");
        }

        /*
		if (fieldName in vueObj.serverErrors) {
			vueObj.serverErrors[fieldName].forEach(error => {
				errors.push(error);
			});
		}
		*/
        return errors;
    },
    preencheFormularios(obForm, obDados) {
        Object.keys(obForm).forEach(key => {
            if (obForm[key] == null || typeof obForm[key] != "object") {
                obForm[key] = obDados[key];
            } else if (obDados[key]) {
                this.preencheFormularios(obForm[key], obDados[key]);
            }
        });
    },
    criaSlug(string) {
        string = string.replace(/[ ?]/g, "-");

        string = string.toLowerCase();
        const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        const to = "aaaaaeeeeeiiiiooooouuuunc------";

        for (let i = 0, l = from.length; i < l; i++) {
            string = string.replace(
                new RegExp(from.charAt(i), "g"),
                to.charAt(i)
            );
        }

        return string;
    }
};
